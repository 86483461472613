// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import '~@coreui/coreui/scss/_dropdown-menu-right.scss';

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

.normal-text {
    font-size: 9pt;
}
.bg-back {
    background-color: rgb(114, 187, 239);
}
.bg-lay {
    background-color: rgb(250, 169, 186);
}
.btn-size {
    padding: 0 4px;
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: #c7cdd2;
  border-color: #c7cdd2;
}
@media (min-width: 768px) {
    .back-lay-w {
        flex: 0 0 83.66667%;
        max-width: 11.66667%;
    }
}

//css for overlay show suspend
.bg-suspend {
    position: absolute;
    background: rgba(255,0,0,0.7);
    left: 0.7em;
    right: 7.7em;
    text-align: center;
    color: white;
}

.bg-suspend-tr {
    background: rgba(255,0,0,0.7);
    color: white;
}
.txt-size {
    width: 43px;
    background-color: rgb(250, 169, 186);
    height: 20px;
}
.delay-area {
  width: 35px !important;
}
.txt-back {
  width: 60px;
  margin-right: -40px;
  background-color: rgb(114, 187, 239);
  height: 20px;
  color: #ffffff;
}
.txt-lay {
  width: 60px;
  margin-right: -40px;
  background-color: rgb(250, 169, 186);
  height: 20px;
}

.badge-txt-size {
  font-weight: 500;
  font-size: small;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
.txt-cursor {
    cursor: pointer;
}

.txt-success {
  color: green;
}
.text-danger {
  color: red;
}

.tbl-thead-width {
  width: 90px;
}

.overlay {
  position: absolute;
  background: rgba(255,0,0,1);
  left: 50%;
  right: 21%;
  height: inherit;
  text-align: center;
  color: white;
  font-size: 10pt;
}
.overlay-f {
  position: absolute;
  background: rgba(255,0,0,1);
  left: 36%;
  right: 27%;
  height: inherit;
  text-align: center;
  color: white;
  font-size: 10pt;
  padding: 11px 0 !important;
}
.overlay-f1 {
  position: absolute;
  background: rgba(255,0,0,1);
  left: 36%;
  right: 27%;
  height: inherit;
  text-align: center;
  color: white;
  font-size: 10pt;
  padding: 8px 0 !important;
}

.bg-color {
  background-color: #2f353a;
  color:white;
}

.bg-back-2 {
  background-color: rgba(114,187,239,.5);
}

.bg-back-1 {
  background-color: rgba(114,187,239,.75);
}
.bg-lay-1 {
  background-color: rgba(250,169,186,.75);
}
.bg-lay-2 {
  background-color: rgba(250,169,186,.5);
}
.betStatus {
  background-color: #f86c6b;
  color: white;
}
.td-width {
  width: 90px;
}
p.no-user-edit {
  padding: 0.375rem 0.75rem;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
  color: #5c6873;
}
.logo-contain {
  // background: linear-gradient(#3a3a3a, #050505);
  background: #000;
  height: 55px;
  display: flex;
  width: 300px;
  justify-content: center;
}
.modal-dialog {
  max-width: 700px;
  margin: 1.75rem auto;
}
.modal-dialog.security-login-pop {
  max-width: 500px;
}
.security-login-pop .modal-content {
  background: white;
  color: black !important;
  border-radius: 20px;
}
.app-header.navbar {
  // background: linear-gradient(#3a3a3a, #050505);
}
.header-fixed .app-body {
  margin-top: 50px;
}
// Sidebar Changes Start
.sidebar .scrollbar-container.sidebar-nav.ps-container ul.nav {
  background-color: #d7d6d2;
  background-image: linear-gradient(180deg,#d7d6d2 10%,#d7d6d2 100%);
  background-size: cover;
  border-top: 2px solid #fff !important;
}
.sidebar .nav-link .nav-icon {
  color: #ffffff;
}
.sidebar .nav-dropdown.open {
  background: inherit;
}
.sidebar .nav-dropdown-items{
  background: #ffffff36;
}
.sidebar .nav-link:hover,.sidebar .nav-link.active {
  color: #fff;
  background: #305bbf;
  // background: linear-gradient(270deg, rgba(236, 102, 24, 0.27) 0%, rgba(248, 172, 26, 0.03) 50%);
}
// .sidebar .nav-link {
//   background: linear-gradient(270deg, rgba(236, 102, 24, 0.27) 0%, rgba(248, 172, 26, 0.03) 50%);
// }
// .sidebar .nav-link.collapsed {
//   background: linear-gradient(to right, rgb(9, 9, 9), rgb(20, 20, 20));
// }

.sidebar .nav-link.active .nav-icon {
  color: #ffffff;
}
.sidebar .nav-dropdown-toggle::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}
.sidebar {
  background: linear-gradient(to right, rgb(9, 9, 9), rgb(20, 20, 20));
}
.sidebar-minimized .sidebar .sidebar-minimizer {
  background-color: #762629;
}
.sidebar .sidebar-minimizer::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}
.btn-primary, .btn-primary:hover {
  background-color: #762629;
  border-color: #762629;
}
.navbar-nav .nav-link {
  color: #305bbf;
}
body {
  color: #305bbf;
  font-family:'Roboto Condensed';
}
.btn-warning {
  color: #fff;
  background-color: #d7d6d2;
  border-color: #d7d6d2;
}
.bg-color {
  background-color: black;
}
.btn-info {
  color: #ffffff;
}
.table {
  color: black;
}
.btn-secondary {
  color: black;
}
.btn-success {
  color: #fff;
}
// Sidebar Changes End
//Login Page dEsign Start
.input-group-text {
  color: #762629;
}
.bcolor{
  background: linear-gradient(90deg, #9181F4, #5038ED);
}
.login-form .card {
    // background: #000;
    border: 1px solid #d7d6d2;
}
.login-logo {
  width: 100%;
  float: left;
}
.login-form h1, .login-form p {
  color: #ffffff;
}
.login-form .error {
  color: #762629 !important;
}
span.error{
  padding: 5px 0;
}
.login-form .btn-link {
  color: #ffffff;
}
span.error {
  width: 100%;
  float: left;
}
span.left-side.input-group-text {
  position: absolute;
  right: 0px;
  z-index: 9;
  height: 35px;
  cursor: pointer;
}
.sidebar-minimized .sidebar .nav-item:hover > .nav-link {
  background: #d7d6d2;
}
.sidebar-minimized .sidebar .nav-item:hover > .nav-link {
  background: #d7d6d2;
}
//Login Page dEsign End
// Header nbalance adjudtment 

.showbalance {
  text-align: left !important;
}
.showbalance span.title {
  font-size: 10px;
}
.showbalance span.amount {
  font-size: 18px;
  line-height: 0px;
}
button.filter.btn.btn-secondary {
  margin: 0px 5px;
  height: 28px;
  padding: 0px 5px;
  color: #fff;
  background-color: #4dbd74;
}
a.user-sign::after {
  content: "";
  color: black;
}
a.user-sign.nav-link {
    padding: 7px 20px !important;
    border: 2px solid #305bbf !important;
    border-radius: 20px;
    margin-right: 30px;
}
i.nav-icon.icon-user {
  font-weight: 900;
}
// Header nbalance adjudtment 
.rstm-tree-item--active {
  background: #d7d6d2 !important;
}
.react-datetime-picker {
  width: 100%;
  border: 1px solid #e4e7ea;
  border-radius: 2px;
}
.react-datetime-picker .react-datetime-picker__wrapper {
  border: none;
}
.react-datetime-picker input, .react-datetime-picker select, .react-datetime-picker button line {
  color: #5c6873;
}
.sidebar-dark .nav-item .nav-link, .sidebar-dark .nav-item .nav-link i {
  color: #fff;
}
.sidebar .nav-item .nav-link {
  padding: 10px 10px;
}
.sidebar .nav-item .nav-link i, .sidebar .nav-item .nav-link:focus i, .sidebar .nav-item .nav-link:hover i {
  // color: #f5aa1a;
}
.sidebar .nav-item .collapse, .sidebar .nav-item .collapsing {
  margin: 0;
}
.bg-org {
  // background: #d7d6d2;
  background: #ec66184d;
}
.bg-org a.collapse-item {
  color: #fff !important;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item:hover, .sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
  background-color: inherit;
}
.app-body .sidebar {
  position: fixed;
  left: 0;
  overflow: auto;
}
select.selct-slide {
  margin-right: 10px;
  height: 30px;
}
.siddebarrr .sidebar {
  border-top: 5px solid #f8ac1a;
}

/* Marquee.css */

.marquee-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #f8f8f8; /* Optional: Background color */
}

.marquee {
  display: flex;
  animation: marquee 40s linear infinite;
  width: max-content; /* Ensures the container resizes based on content */
}

.marquee img {
  width: 65px; /* Adjust the size of the images */
  margin-right: 20px; /* Space between images */
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}


// Resposive
@media (min-width: 768px) {
  
  .siddebarrr .sidebar {
    margin: 0 !important;
    width: 300px !important;
  }
  .sidebar-lg-show .siddebarrr .sidebar {
    margin: -200px !important;
  }
  .sidebar-lg-show .app-body .main {
    margin-left: 0px;
  }
  .app-body .main {
    margin-left: 300px;
  }
  .app-footer {
    margin-left: 300px;
  }
  .sidebar-lg-show .app-footer {
    margin-left: 0px;
  }
}
@media (max-width:768px) {
  .sidebar .nav-item .collapse {
    position: unset;
  }
  .sidebar-show .sidebar {
    z-index: 10000;
  }
  .app-header {
    height: 100px;
  }
  .header-fixed .app-body {
    margin-top: 105px;
  }
  .sidebar .nav-item .nav-link {
    width: inherit;
    text-align: left;
  }
  .sidebar .nav-item .nav-link span {
    display: inline-block;
    margin-left: 10px;
  }
}
// Responsive